import request from "./request";

export async function getGameList(url) {
  return request({
    url: url,
    options: {
      method: "GET",
    },
  });
}
