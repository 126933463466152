import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import likeIcon from "../../assets/images/like.png";
import FloatNav from "../FloatNav";
import { HOST } from "../../utils/tools";
import { GameTopBanner } from "../Ad";

export default function GamePlayCard(props) {
  const { icon, name, stars, playCounts, url } = props;
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showAd, setShowAd] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    let interval;

    if (loading && loadingProgress < 100) {
      const speed = loadingProgress < 50 ? 50 : 80;
      interval = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProcess = prev + 1;
          if (newProcess >= 100) {
            if (iframeRef.current) {
              iframeRef.current.style.display = "block";
              setShowAd(true);
            }
            clearInterval(interval);
          }
          return newProcess;
        });
      }, speed);
    }
    return () => clearInterval(interval);
  }, [loading, loadingProgress]);

  const handleGameShow = () => {
    if (loading) return;
    setLoading(true);
    setLoadingProgress(0);
  };

  const handleIframeLoaded = () => {
    setLoadingProgress(100);
    setIframeLoaded(true);
    setLoading(false);
    if (loadingProgress === 100 && iframeRef.current) {
      iframeRef.current.style.display = "block";
      setShowAd(true);
    }
  };

  const renderStars = useMemo(() => {
    return Array.from({ length: stars }).map((_, index) => (
      <span key={index} className="star"></span>
    ));
  }, [stars]);
  return (
    <Fragment>
      <div
        className="w-ful px-[0.5rem] py-[1rem] relative rounded-lg min-h-[14rem]"
        style={{
          background: "rgba(0,0,0,0.2)",
          overflow: "hidden",
        }}
      >
        <div
          className="w-full h-full"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            background: `url(${icon})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(10px)",
          }}
        ></div>
        <div className=" w-[90%] left-[50%] -translate-x-[50%] flex items-center justify-between absolute">
          <div className="w-[4rem] h-[4rem] rounded-[50%] bg-white text-center flex items-center justify-center">
            <img
              className="w-[90%] h-auto rounded-[50%]"
              src={icon}
              alt={name}
            />
          </div>
          <div className="flex-auto mx-[1rem]">
            <h3 className="text-[1.2rem] text-white font-bold max-w-[12rem] overflow-hidden text-nowrap text-ellipsis">
              {name}
            </h3>
            <div className="flex items-center -translate-y-[0.2rem]">
              {renderStars}
            </div>
          </div>
          <div className="w-min flex items-center justify-center">
            <img
              src={likeIcon}
              alt="like"
              className="w-[1.25rem] h-[1.25rem] object-fill"
            />
            <span className=" text-[0.75rem] text-white inline-block ml-[0.25rem]">
              {parseInt(playCounts)}
            </span>
          </div>
        </div>
        <div
          className="w-full relative top-[5rem] mt-[4rem] mb-[1rem] h-[3.4rem] flex items-center justify-center rounded-[2rem] bg-[#ff9901] text-white  text-[1.25rem] font-bold shadow-2x cursor-pointer animate-bounce"
          onClick={handleGameShow}
        >
          {loading
            ? loadingProgress === 100
              ? "Loaded"
              : `Loading...${loadingProgress}%`
            : "Play now"}
        </div>
      </div>
      <FloatNav />
      {showAd ? <GameTopBanner /> : null}
      <iframe
        ref={iframeRef}
        title={name}
        onLoad={handleIframeLoaded}
        src={`https://${HOST}${url}`}
        className="w-screen h-screen absolute top-0 left-0 z-[10] hidden overflow-hidden"
      />
    </Fragment>
  );
}
