import { createSlice } from "@reduxjs/toolkit";
// import allGames from "../../publicGames.json";
// import {
//   getHadBannerGames,
//   getHotGames,
//   getRecommendGames,
// } from "../../utils/tools";

// const AllGameList = JSON.parse(JSON.stringify(allGames));

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    gameList: [],
    hotGames: [],
    recommendGames: [],
    hadBannerGames: [],
    // gameList: AllGameList,
    // hotGames: getHotGames(20, AllGameList),
    // recommendGames: getRecommendGames(20, AllGameList),
    // hadBannerGames: getHadBannerGames(20, AllGameList),
  },
  reducers: {
    initGameList: (state, action) => {
      state.gameList = action.payload;
    },
    initHotGames: (state, action) => {
      state.hotGames = action.payload;
    },
    initRecommendGames: (state, action) => {
      state.recommendGames = action.payload;
    },
    initHadBannerGames: (state, action) => {
      state.hadBannerGames = action.payload;
    },
  },
});

export const {
  initGameList,
  initHotGames,
  initRecommendGames,
  initHadBannerGames,
} = homeSlice.actions;

export const selectGameList = (state) => state.home.gameList;
export const selectHotGames = (state) => state.home.hotGames;
export const selectRecommendGames = (state) => state.home.recommendGames;
export const selectHadBannerGames = (state) => state.home.hadBannerGames;

export default homeSlice.reducer;
