export const HOST =
  process.env.NODE_ENV === "production"
    ? window.location.host
    : "solidgame.vip";

export function getHotGames(count, gameList, type) {
  if (type === "all") {
    return [...gameList].slice(0, count);
  } else {
    const sortList = [...gameList].sort(
      (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
    );
    return sortList.slice(0, count);
  }
}

export function getRecommendGames(count, gameList) {
  let targetList = [...gameList].filter((game) => game.recommended > 0);
  if (targetList.length === 0) {
    const shuffledArray = [...gameList].sort(() => 0.5 - Math.random());
    targetList = shuffledArray.slice(0, 40);
  }
  return targetList.slice(0, count);
}

export function getHadBannerGames(count, gameList) {
  const targetList = [...gameList].filter((game) => game.banner.length > 0);
  const sortList = targetList.sort(
    (a, b) => parseInt(b.playCounts) - parseInt(a.playCounts)
  );
  return sortList.slice(0, count);
}
