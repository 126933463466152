import React, { useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const PubId = "6451363172123483";

// export const HomeTopBanner = () => {
//   return (
//     <div className="flex items-center justify-center mb-[0.5rem]">
//       <Adsense
//         client={`ca-pub-${PubId}`}
//         slot={"2695908226"}
//         style={{
//           width: 300,
//           height: 250,
//         }}
//         format=""
//       />
//     </div>
//   );
// };

// export const DetailMidBanner = () => {
//   return (
//     <div className="flex items-center justify-center my-[0.5rem]">
//       <Adsense
//         client={`ca-pub-${PubId}`}
//         slot={"4975158026"}
//         style={{
//           width: 300,
//           height: 250,
//         }}
//         format=""
//       />
//     </div>
//   );
// };

export const HomeTopBanner = () => {
  return (
    <div className="flex items-center justify-center mb-[0.5rem] w-[350px] h-auto mx-auto">
      <Adsense
        client={`ca-pub-${PubId}`}
        slot={"7023672649"}
        style={{ display: "block" }}
        responsive="true"
        format=""
      />
    </div>
  );
};

export const DetailMidBanner = () => {
  return (
    <div className="flex items-center justify-center my-[0.5rem] w-[350px] h-auto mx-auto">
      <Adsense
        client={`ca-pub-${PubId}`}
        slot={"5442632709"}
        style={{ display: "block" }}
        responsive="true"
        format=""
      />
    </div>
  );
};

export const DetailBottomBanner = () => {
  const [show, setShow] = useState(true);

  const handleRemove = () => {
    setShow(false);
  };
  if (!show) return null;
  return (
    <div className=" fixed w-full min-h-[50px] bottom-0 left-[50%] -translate-x-[50%]">
      <span
        className=" w-[1.5rem] h-[1.5rem] flex items-center justify-center absolute -top-[1.5rem] right-[0.5rem] text-2xl text-[#000] cursor-pointer rounded-[50%] border border-[#000] z-[99] "
        onClick={handleRemove}
      >
        X
      </span>
      <div className="flex items-center justify-center bg-[#ddd]">
        <Adsense
          client={`ca-pub-${PubId}`}
          slot={"4938928185"}
          style={{
            width: 320,
            height: 50,
          }}
          format=""
        />
      </div>
    </div>
  );
};

export const GameTopBanner = () => {
  const [show, setShow] = useState(true);

  const handleRemove = () => {
    setShow(false);
  };
  if (!show) return null;
  return (
    <div className=" fixed w-full top-0 left-[50%] -translate-x-[50%] min-h-[60px] z-[999]">
      <span
        className=" w-[1.5rem] h-[1.5rem] flex items-center justify-center absolute top-[3rem] left-[0.5rem] text-2xl text-[#000] cursor-pointer rounded-[50%] border border-[#000] z-[999] "
        onClick={handleRemove}
      >
        X
      </span>
      <div className="flex items-center justify-center bg-[#ddd]">
        <Adsense
          client={`ca-pub-${PubId}`}
          slot={"4075239393"}
          style={{
            width: 320,
            height: 50,
          }}
          format=""
        />
      </div>
    </div>
  );
};
