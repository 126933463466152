import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { getGameList } from "./services/api";
import {
  getHotGames,
  getHadBannerGames,
  getRecommendGames,
} from "./utils/tools";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const link = "https://image.glifegame.com/jihezhan/premium.miniglife1.json";

getGameList(link).then((res) => {
  if (res) {
    const list = JSON.parse(JSON.stringify(res));
    store.dispatch({
      type: "home/initGameList",
      payload: list,
    });
    store.dispatch({
      type: "home/initHotGames",
      payload: getHotGames(20, list),
    });
    store.dispatch({
      type: "home/initHadBannerGames",
      payload: getHadBannerGames(20, list),
    });
    store.dispatch({
      type: "home/initRecommendGames",
      payload: getRecommendGames(20, list),
    });
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
